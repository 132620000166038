@use '~@gr4vy/poutine-tokens/scss/index.scss' as *;

.summary {
  gap: $space-8 $space-32;
}

.requestCopy {
  position: relative;
  top: calc($space-80 + $space-4);
  right: $space-8;
}

.responseCopy {
  position: relative;
  top: $space-56;
  right: $space-8;
}
