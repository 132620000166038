@use '~@gr4vy/poutine-tokens/scss/index.scss' as *;

.ruleCard {
  border-style: solid;
  background-color: $color-white;
  border-color: $color-gray-10;
  box-shadow: $shadow-1;
  margin-top: $space-8;
  margin-bottom: $space-8;

  &:active {
    box-shadow: $shadow-4;
  }

  .ruleCardButtonLink {
    &:active {
      :global(.icon-edit > div) {
        background: $color-white !important;
      }
    }
  }
}

.newRuleCard {
  box-shadow: 0 0 0 4px $color-blue-20;
  -moz-animation: cssAnimation 0.2s ease-in 3s forwards;
  -webkit-animation: cssAnimation 0.2s ease-in 3s forwards;
  -o-animation: cssAnimation 0.2s ease-in 3s forwards;
  animation: cssAnimation 0.2s ease-in 3s forwards;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
@keyframes cssAnimation {
  to {
    box-shadow: $shadow-1;
  }
}
@-webkit-keyframes cssAnimation {
  to {
    box-shadow: $shadow-1;
  }
}
