@use '~@gr4vy/poutine-tokens/scss/index.scss' as *;

button.trigger {
  padding: $space-8;
  margin-left: $space-8;
  margin-right: $space-8;

  &[data-state='open'] {
    background: $color-blue-90;
    color: $color-white;
  }
}

div.option {
  &:active {
    color: $color-gray-100;
  }
  &:hover {
    background-color: $color-gray-10;
  }
  &[data-active='true'] {
    background-color: $color-blue-10;
    color: $color-blue-70;
    font-weight: $font-weight-bold;
  }
  &[data-disabled='true'] {
    pointer-events: none;
  }
}
