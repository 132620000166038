@use '~@gr4vy/poutine-tokens/scss/index.scss' as *;

.notificationField :global(.ant-form-item-control-input) {
  min-height: 0;
}

.input :global(.ant-form-item-control-input) {
  input {
    height: 38px !important;
    line-height: 38px;
    border-radius: 0;
  }
}
