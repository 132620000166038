@use '~@gr4vy/poutine-tokens/scss/index.scss' as *;

.sideNav {
  padding-top: 4px;
  margin-left: $space-72;
  margin-right: $space-4;

  :global(.ant-anchor) {
    display: flex;
    flex-direction: column;
    gap: $space-8;

    &::before {
      display: none;
    }

    :global(.ant-anchor-ink) {
      display: none !important;
    }

    :global(.ant-anchor-link) {
      padding: 0 !important;

      &:last-child {
        padding-bottom: $size-8 !important;
      }

      > a {
        color: $color-black;
        width: 100%;
        height: $size-32;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0 $size-8;
        border-radius: $radius-rounded;

        &:hover {
          color: $color-black;
          background-color: $color-gray-10;
        }

        &:active,
        &:global(.ant-anchor-link-title-active) {
          color: $color-black;
          background-color: $color-gray-20;
        }

        &:focus {
          box-shadow: $shadow-ring-4;
        }
      }

      &:global(.title) {
        display: flex;
        flex-direction: column;
        gap: $space-8;

        > a {
          pointer-events: none;
          background-color: initial;
          margin-bottom: -$space-8;
        }
      }

      &:global(.titleLink) {
        display: flex;
        flex-direction: column;
        gap: $space-8;

        > a {
          > h4 {
            height: auto;
          }
        }
      }
    }
  }
}
