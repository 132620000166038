@use '~@gr4vy/poutine-tokens/scss/index.scss' as *;

.close {
  cursor: pointer;

  > svg {
    pointer-events: none;
    width: $size-20;
  }
}
