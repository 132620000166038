@use '~@gr4vy/poutine-tokens/scss/index.scss' as *;

.dropdown {
  .disabled {
    color: $color-gray-60;
  }

  .remaining {
    display: initial;
  }
}
