@use '~@gr4vy/poutine-tokens/scss/index.scss' as *;

.list {
  list-style: none;
  counter-reset: li;
  padding-left: $size-16;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: $space-24;
  max-width: $size-readable;
}

.item {
  display: flex;
  gap: $space-16;
}

.item::before {
  background: $color-blue-60;
  counter-increment: li;
  content: counter(li);
  color: $color-white;
  display: block;
  flex-shrink: 0;
  width: $size-24;
  height: $size-24;
  line-height: $size-24;
  text-align: center;
  border-radius: $radius-circle;
  font-size: $font-size-sm;
}
