@use '~@gr4vy/poutine-tokens/scss/index.scss' as *;

.outcomeCard {
  background-color: $color-white;
  border-style: solid;
  border-color: $color-gray-20;
  box-shadow: $shadow-1;
  margin-top: $space-8;
  margin-bottom: $space-8;

  img {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
  }

  &:hover svg {
    color: $color-blue-70;
  }

  &:hover a {
    background-color: $color-blue-10;
  }

  &:active {
    box-shadow: $shadow-4;
  }

  &:active svg {
    color: $color-blue-90;
  }

  &:active a {
    color: $color-white;
    background-color: $color-blue-90;
  }
}

.warningOutcomeCard {
  border-color: $color-amber-60;
}

.hiddenOutcomeCard {
  background-color: $color-gray-10;
  border-color: transparent;
  box-shadow: none;
}

.outcomeMessage {
  background-color: $color-gray-10;
  border-style: dashed;
  color: $color-blue-60;
  border-color: $color-blue-60;
  margin-top: $space-8;
  margin-bottom: $space-8;
}

.outcomeError {
  color: $color-red-80;
  border-color: $color-red-60;
}
