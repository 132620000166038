@use '~@gr4vy/poutine-tokens/scss/index.scss' as *;

.icon {
  height: inherit;
  width: inherit;
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.icon_add {
  @extend .icon;
  background-color: $color-blue-60;
  mask: url('/images/icons/add_plus.svg') center no-repeat;

  &:hover {
    background-color: $color-blue-70;
  }

  &:active {
    background-color: $color-blue-90;
  }
}

.icon_arrow_right {
  @extend .icon;
  height: $size-20;
  width: $size-20;
  background-color: $color-white;
  mask: url('/images/icons/arrow_right.svg') center no-repeat;
}

.icon_info {
  @extend .icon;
  mask: url('/images/icons/circle_info.svg') center no-repeat;
}

.icon_danger {
  @extend .icon;
  mask: url('/images/icons/circle_strike.svg') center no-repeat;
}

.icon_top {
  @extend .icon;
  mask: url('/images/icons/back_to_top.svg') center no-repeat;
}

.icon_drag {
  @extend .icon;
  mask: url('/images/icons/drag_horizontal.svg') center no-repeat;

  &:hover {
    background-color: $color-blue-70;
  }

  &:active {
    background-color: $color-blue-90;
  }
}

.icon_edit {
  @extend .icon;
  mask: url('/images/icons/edit_pencil_line_02.svg') center no-repeat;

  &:active {
    background-color: $color-white;
  }
}

.icon_bin {
  @extend .icon;
  mask: url('/images/icons/trash_empty_delete.svg') center no-repeat;

  &:hover {
    cursor: pointer;
  }
}

.icon_warning {
  @extend .icon;
  background-color: $color-amber-60;
  mask: url('/images/icons/triangle_warning.svg') center no-repeat;
}

.icon_line {
  @extend .icon;
  mask: url('/images/icons/line_divider.svg') center no-repeat;
}
