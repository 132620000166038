@use '~@gr4vy/poutine-tokens/scss/index.scss' as *;

.box {
  box-shadow: $shadow-1;
  border-radius: $radius-rounded;
}

.details {
  padding: $size-24 $size-24 $size-16;
  gap: $size-16;
}

.title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.description {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  height: $size-64;
  margin: 0;
}

.actions {
  background: $color-gray-10;
  border-radius: 0 0 $radius-rounded $radius-rounded;
  padding: $size-16 $size-24;
  gap: $size-16;
}
