@use '~@gr4vy/poutine-tokens/scss/index.scss' as *;

.addNewRuleCardLink {
  &:hover {
    color: $color-blue-70;

    > div {
      border-color: $color-blue-70;
    }

    :global(.icon-add > div) {
      background: $color-blue-70 !important;
    }
  }

  &:focus > div {
    box-shadow: $shadow-ring-4;
    border-color: transparent;
  }

  &:active {
    color: $color-blue-90;

    > div {
      border-color: $color-blue-90;
      background-color: $color-blue-10;
    }

    :global(.icon-add > div) {
      background: $color-blue-90 !important;
    }
  }
}

.addNewRuleCard {
  border-style: solid;
  border-color: $color-blue-60;
  background-color: $color-white;
  box-shadow: $shadow-1;
  margin-bottom: $space-8;

  :global(.icon-add > div) {
    background: $color-blue-60 !important;
  }

  a {
    color: $color-blue-60;
  }
}

.disabledAddNewRuleCard {
  border-style: solid;
  border-color: $color-gray-70;
  color: $color-gray-70;
  background-color: $color-gray-10;
  margin-bottom: $space-8;

  :global(.icon-add > div) {
    background: $color-gray-70 !important;
  }
}
