@use '~@gr4vy/poutine-tokens/scss/index.scss' as *;

.flow {
  gap: $space-24 $space-40;
  margin-bottom: 0;

  dt {
    padding-left: $space-24;
    position: relative;
    height: unset;
    align-items: flex-start;
    line-height: $size-20;

    &::before {
      content: '';
      display: block;
      position: absolute;
      width: $space-8;
      height: $space-8;
      border-radius: $space-4;
      background: $color-gray-70;
      left: 0px;
      top: 0.4rem;
      z-index: 10;
      box-shadow: 0px -6px 0px #fff;
    }

    &::after {
      padding: 0;
      margin: 0;
      display: block;
      content: '';
      width: $border-width-md;
      height: 5rem;
      background-color: $color-gray-30;
      position: absolute;
      left: 3px;
      top: 0.7rem;
    }

    &:last-of-type {
      &::after {
        background-color: #fff;
      }
    }
  }

  dd {
    height: unset;
    align-items: flex-start;

    > span {
      line-height: $size-20;
    }

    &:last-of-type {
      height: $space-32;
    }
  }
}
