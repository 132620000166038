@use '~@gr4vy/poutine-tokens/scss/index.scss' as *;

.connectionCard {
  border-radius: $radius-rounded;
  box-shadow: $shadow-1;
  min-height: 128px;
}

.connectionCardLink {
  border-radius: $radius-rounded;

  &:hover {
    > div {
      box-shadow: $shadow-4;
    }
  }

  &:active {
    > div {
      box-shadow: $shadow-4;
      :global(.connectionCardBanner) {
        background-color: $color-gray-20;
      }
    }
  }

  &:focus {
    > div {
      box-shadow: $shadow-ring-4;
    }
  }
}

.disabledConnectionCard {
  :global(.connectionCardContent > div) {
    color: $color-gray-70;
  }

  :global(.connectionCardContent > h4) {
    color: $color-gray-80;
  }
}
