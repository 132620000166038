@use '~@gr4vy/poutine-tokens/scss/index.scss' as *;

.noWrap {
  white-space: nowrap;
}

.logoContainer {
  margin-left: -#{$space-8};

  a {
    border-radius: $radius-rounded;
  }
}

.divider {
  background-color: $color-white;
  width: $border-width-sm;
  height: $size-24;
}
