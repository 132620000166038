@use '~@gr4vy/poutine-tokens/scss/index.scss' as *;

.contentWrapper {
  min-width: $size-200 * 2;
  padding: $space-8;
}

.numberInput {
  &:global(.ant-form-item) {
    :global(.ant-input) {
      height: $size-32;
    }

    :global(.ant-form-item-explain-error) {
      font-size: $font-size-xs;
    }
  }
}
