@use '~@gr4vy/poutine-tokens/scss/index.scss' as *;

$chart-height: calc($size-120 * 3);
$legend-spacing: $size-48 * 2;

.loading {
  width: $size-200;
  position: absolute;
  display: grid;
  place-items: center;
  left: 0;
  right: 0;
  margin: auto;
  top: calc($size-24 / 4);
  height: calc($chart-height - $legend-spacing);
  left: calc($size-72 / 2);
  z-index: 1;

  > svg {
    animation: 1.2s steps(8) spinning infinite;
  }
}

@keyframes spinning {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.lineChart {
  position: relative;
  height: calc($size-120 * 3);
  width: 100%;
}
