@use '~@gr4vy/poutine-tokens/scss/index.scss' as *;

.tabBarLink {
  color: $color-gray-80;
  font-family: $font-body;
  padding-top: $space-4;
  line-height: 18px;
  padding-bottom: calc($space-8 + $border-width-md);
  border-color: $color-gray-10;
  border-width: 0;
  border-radius: $radius-square;
  border-style: solid;
  text-align: center;

  &::before {
    display: block;
    content: attr(title);
    font-weight: $font-weight-bold;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }

  &:focus,
  &.selected:focus {
    border-color: $color-blue-60;
    box-shadow:
      inset 2px 0 0 0 $color-blue-60,
      inset -2px 0 0 0 $color-blue-60,
      inset 0 2px 0 0 $color-blue-60,
      inset 0 -2px 0 0 $color-blue-60;
  }

  &:hover {
    color: $color-blue-60;
    box-shadow: inset 0 -2px 0 0 $color-blue-60;
  }

  &:active {
    color: $color-blue-60;
    box-shadow: inset 0 -2px 0 0 $color-blue-60;
  }

  &.selected {
    color: $color-black;
    box-shadow: inset 0 -2px 0 0 $color-blue-60;
    font-weight: $font-weight-bold;
  }
}
