@use '~@gr4vy/poutine-tokens/scss/index.scss' as *;

.quickFilterButton {
  display: inline-flex;
  align-items: center;
  width: fit-content;
  white-space: nowrap;
  height: $size-32;
  background: $color-gray-10;
  border-radius: $radius-rounded;
  font: $font-weight-bold calc($font-size-sm / $size-16) + rem $font-body;
  border-width: 0;
  padding: 0 calc($space-24 / 2);
  cursor: pointer;

  &:focus-visible {
    outline: none;
    box-shadow: $shadow-ring-4;
  }

  &[data-selected='true'] {
    background: $color-blue-10;
  }

  &:not(:focus):hover {
    background: $color-gray-20;
  }
}
