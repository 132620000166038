@use '~@gr4vy/poutine-tokens/scss/index.scss' as *;

.contentWrapper {
  padding: $space-16;
  margin: -#{$space-16};
}

.item {
  height: $size-32;
  padding: $space-8;
}
