@use '~@gr4vy/poutine-tokens/scss/index.scss' as *;

* {
  font-family: 'Inter', sans-serif;
  font-variant-numeric: normal;
  font-feature-settings: normal;
}

body {
  height: 100%;
  color: $color-gray-100;
}

p {
  margin: 0;
}

/* Ant design override */

/* Select */

.ant-select-dropdown {
  padding: 0;

  .ant-select-item-option {
    height: $size-32;
    line-height: $size-16;
    border-radius: 0;

    .ant-select-item-option-content {
      overflow: initial;
    }
  }
}

/* Input */

.ant-input {
  height: $size-40;
  font-size: $font-size-sm;
}

.ant-input-affix-wrapper {
  padding-top: 0;
  padding-bottom: 0;
  height: $size-40;

  .ant-input {
    height: 38px;
  }
}

.ant-picker {
  height: $size-40;
}

/* Date/DateRange picker */

.ant-picker .ant-picker-input span.ant-picker-suffix,
.ant-picker.ant-picker-range span.ant-picker-suffix {
  margin-left: 0;
  margin-right: $size-8;
  order: -1;
}

.ant-picker-body {
  table.ant-picker-content {
    thead {
      th {
        height: 32px;
        line-height: 32px;
      }
    }
    .ant-picker-cell {
      padding: 3px 0;
    }
  }
}

/* Label */

.ant-form-item-has-error {
  .poutine-input {
    border: 1px solid $color-red-80;
  }

  label p,
  .poutine-input-prefix {
    color: $color-red-80;
  }
}

/* Form field error */

.ant-form-item-explain-error {
  margin-top: $size-8;
}

/* Skeleton */

@keyframes skeleton-loading {
  0% {
    background: $color-gray-30;
  }
  50% {
    background: $color-gray-40;
  }
  100% {
    background: $color-gray-30;
  }
}

.ant-skeleton .ant-skeleton-content .ant-skeleton-title,
.ant-skeleton .ant-skeleton-content .ant-skeleton-paragraph li {
  animation: skeleton-loading 2s ease infinite;
}

/* Message */

.ant-message {
  bottom: $size-32;
  top: auto !important;
  font-weight: $font-weight-bold;

  .ant-message-notice-wrapper {
    .ant-message-notice-content {
      border: 1px solid $color-gray-30;
      box-shadow: $shadow-1;
      padding: $size-8 $size-16;
      border-radius: $radius-rounded;
    }
  }
}

/* Code block */
pre {
  code {
    span {
      font-family: $font-mono;
    }
  }
}
