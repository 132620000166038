@use '~@gr4vy/poutine-tokens/scss/index.scss' as *;

.formItem {
  :global(.ant-form-item-label) {
    label {
      height: auto;
      line-height: $size-16;
    }
  }
}
