@use '~@gr4vy/poutine-tokens/scss/index.scss' as *;

.draggingOver {
  > div[data-message-type='outcomeMessage'] {
    background-color: $color-blue-10 !important;
  }

  > div[data-message-type='outcomeError'] {
    background-color: $color-red-10 !important;
  }
}

.hiddenInput {
  :global(.ant-form-item-control-input),
  :global(.ant-form-item-control-input-content) {
    min-height: 0;
    height: 0;
    line-height: 0;
  }
}
