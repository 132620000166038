@use '~@gr4vy/poutine-tokens/scss/index.scss' as *;

.dropdown {
  :global(.ant-picker-footer-extra) {
    border-bottom: $border-width-none !important;
  }

  :global(.ant-picker-footer .ant-picker-ranges) {
    display: none;
  }
}
