@use '~@gr4vy/poutine-tokens/scss/index.scss' as *;

.item {
  border-radius: $radius-rounded;
  padding: $space-8;
  cursor: pointer;

  &.sm {
    height: $size-32;
  }

  &.md {
    height: $size-40;
  }

  &.disabled {
    color: $color-gray-70;
  }

  &.active,
  &:active {
    background: $color-gray-20;
  }

  &:hover {
    background: $color-gray-10;
  }

  &:focus {
    outline: none;
    box-shadow: $shadow-ring-2;
  }
}
