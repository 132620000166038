@use '~@gr4vy/poutine-tokens/scss/index.scss' as *;

.contentWrapper {
  padding: $space-16 $space-24;
  margin: -#{$space-16};
  width: $size-160 * 2;
}

.searchBar {
  width: 100%;
}
