@use '~@gr4vy/poutine-tokens/scss/index' as *;

.datePicker {
  margin-bottom: $space-8;

  &:global(.ant-picker) {
    height: $size-32;
  }
}

.container {
  min-width: 451px;

  > div {
    &:last-child {
      position: initial !important;
    }
  }

  :global(.ant-picker-dropdown) {
    position: initial !important;
    transform: scaleY(1) !important;
    opacity: 1 !important;

    :global(.ant-picker-range-wrapper) {
      :global(.ant-picker-range-arrow) {
        display: none;
      }

      :global(.ant-picker-ranges) {
        display: none;
      }

      :global(.ant-picker-panel-container) {
        box-shadow: none;
        border: $border-width-sm solid $color-gray-30;

        :global(.ant-picker-footer-extra:not(:last-child)) {
          border-bottom: none;
        }
      }
    }
  }
}
