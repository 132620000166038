@use '~@gr4vy/poutine-tokens/scss/index.scss' as *;

.select {
  &:global(.ant-select) {
    height: $size-32;

    :global(.ant-select-selector),
    :global(.ant-select-selection-item) {
      height: $size-32;
      min-height: $size-32 !important;
      line-height: $size-32;
      align-self: center;
    }
  }
}
