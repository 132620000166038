@use '~@gr4vy/poutine-tokens/scss/index.scss' as *;

.arrow-after {
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.trigger {
  all: unset;
  background-color: $color-white;
  padding: $space-16;
  position: relative;
  cursor: pointer;

  &:not([data-state='active']):hover {
    background-color: $color-gray-10;
  }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: $border-width-md;
    background-color: $color-gray-60;
    height: 100%;
  }

  &:focus-visible {
    outline: none;
    box-shadow: $shadow-ring-2;

    .arrow {
      position: absolute;
      top: 0;
      bottom: 0;
      right: $size-8;

      &::after {
        @extend .arrow-after;
        border: $space-16 solid transparent;
        border-left: $space-16 solid $color-gray-20;
        filter: drop-shadow(3px 0 0 $color-blue-20);
        z-index: -1;
      }
    }
  }

  &[data-state='active']:not([data-loading='true'], [disabled]) {
    background-color: $color-gray-20;

    &::before {
      background-color: $color-blue-60;
    }

    &::after {
      @extend .arrow-after;
      border: $space-8 solid transparent;
      border-left: $space-8 solid $color-gray-20;
      right: -$size-16;
    }
  }

  &[data-loading='true'],
  &[disabled] {
    pointer-events: none;
  }
}

h4 {
  flex-shrink: 0;
}
