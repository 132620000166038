@use '~@gr4vy/poutine-tokens/scss/index.scss' as *;

.timeLineWrapper {
  padding: $space-16 $space-16 $space-4 $space-16;
}

.infoIcon {
  align-self: start;
  flex-shrink: 0;
}
