@use '~@gr4vy/poutine-tokens/scss/index.scss' as *;

.item {
  border-radius: $radius-rounded;
  padding: $space-8;
  cursor: pointer;

  > div {
    > div {
      height: auto;
      gap: $size-8;
    }
  }

  &:hover {
    background: $color-gray-10;
  }

  &.active {
    background: $color-gray-20;
  }

  &:focus {
    outline: none;
    box-shadow: $shadow-ring-2;
  }
}
