@use '~@gr4vy/poutine-tokens/scss/index.scss' as *;

.content {
  background-color: white;
  z-index: $z-index-100;
  box-shadow: $shadow-4;
  border-radius: $radius-rounded;
}

.accounts {
  max-height: calc($size-120 * 3);
  overflow-y: auto;
  // prevents the box-shadow from being cut off because of overflow
  padding: $space-4 $space-4;
  margin: calc(($space-4) * -1);
}

.item {
  all: unset;
  cursor: pointer;
  border-radius: $radius-rounded;
  padding: $space-4 $space-16;
  color: inherit;

  &.active {
    background-color: $color-gray-20;
  }

  &:focus-visible {
    outline: 0;
    box-shadow: $shadow-ring-2;
  }

  &:hover {
    background-color: $color-gray-10;
    color: inherit;
  }
}
