@use '~@gr4vy/poutine-tokens/scss/index.scss' as *;

.menu {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: $space-4;
  max-height: $size-160 * 2;
  overflow-y: auto;
  // prevents the box-shadow from being cut off because of overflow
  padding: 0.2rem;
  margin: -0.2rem calc((0.2rem + $size-8) * -1);
}
