@use '~@gr4vy/poutine-tokens/scss/index.scss' as *;

.badge {
  background-color: $color-white;
  height: $size-16;
  border-radius: $radius-rounded;
  padding: 0px $space-8;
  width: fit-content;

  &[data-variant='positive'] {
    color: $color-green-90;
  }

  &[data-variant='negative'] {
    color: $color-red-90;
  }

  &[data-variant='neutral'] {
    color: $color-gray-70;
  }
}
