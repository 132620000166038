@use '~@gr4vy/poutine-tokens/scss/index.scss' as *;

.wrapper {
  padding: $space-16;
  margin: -#{$space-16};
}

.item {
  height: $size-32;
  padding: $space-8;
  border-radius: $radius-rounded;

  &:hover {
    background-color: $color-gray-20;
  }

  &:focus {
    outline: 0;
    box-shadow: $shadow-ring-2;
  }
}
