@use '~@gr4vy/poutine-tokens/scss/index.scss' as *;

.input {
  &:global(.ant-input-affix-wrapper) {
    height: $size-32;
    overflow: hidden;

    :global(.ant-input) {
      height: $size-32;
      align-self: center;
    }
  }
}

.wrapper {
  width: $size-240 * 2;
  overflow-y: auto;
  max-height: calc($size-240 + $size-120);
}
