button.trigger {
  max-width: var(--char-limit);
  background: transparent;

  &:hover {
    backdrop-filter: brightness(140%);
    background: transparent;
  }
  &.active {
    backdrop-filter: brightness(160%);
    background: transparent;
  }
  &.readOnly {
    pointer-events: none;
    user-select: none;
  }
}
