@use '~@gr4vy/poutine-tokens/scss/index.scss' as *;

.inputSmall {
  &:global(.ant-input-affix-wrapper) {
    height: $size-32;
    overflow: hidden;
    width: $size-64 * 6;

    :global(.ant-input) {
      height: $size-32;
      align-self: center;
    }
  }
}
