@use '~@gr4vy/poutine-tokens/scss/index.scss' as *;

.container.disabled {
  cursor: not-allowed;
}

.dropzone {
  height: $size-160;
  border-radius: $radius-rounded;
  border: $border-width-sm dashed $color-blue-60;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color-blue-60;
  font-size: $font-size-sm;
  font-weight: $font-weight-normal;
  padding: $space-48;
  text-align: center;
  cursor: pointer;
  overflow: hidden;

  &:active,
  &:focus,
  &:focus-visible {
    outline: none;
    box-shadow: $shadow-ring-4;
    border-style: dashed;
  }

  &:hover {
    background: $color-blue-10;
  }

  &.fileHover {
    background: $color-blue-10;
    box-shadow: $shadow-ring-4;
  }

  &.selected {
    color: $color-gray-100;
  }

  &.uploading {
    color: $color-black;
    font-weight: $font-weight-medium;
    background: $color-gray-20;
    border-color: $color-gray-60;
  }

  &.disabled {
    pointer-events: none;
    color: $color-gray-70;
    background: $color-gray-20;
    border-color: $color-gray-60;
  }
}

.dropzone--small {
  height: $size-80;
}

@keyframes spinning {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  animation: 1.2s steps(8) spinning infinite;
}
