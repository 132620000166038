@use '~@gr4vy/poutine-tokens/scss/index.scss' as *;

$size: $font-size-sm;
$color: var(--status-dot-color, $color-gray-70);

.dot {
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: $size-8;

  &:before {
    content: '';
    width: $size;
    height: $size;
    background-color: $color;
    border: $border-width-md solid transparent;
    border-radius: $size;
  }
}

.pending,
.processing {
  &:before {
    background-color: transparent;
    border-color: $color;
  }

  &:after {
    content: '';
    position: absolute;
    left: 3px;
    width: 0;
    height: 0;
    border-radius: $border-width-lg;
    border: $border-width-lg solid $color;
    border-top-color: transparent;
    transform: rotate(-45deg);
  }
}

.processing {
  &:after {
    border-left-color: transparent;
  }
}
