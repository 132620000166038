@use '~@gr4vy/poutine-tokens/scss/index.scss' as *;

.value {
  display: flex;
  align-items: center;
  margin: 0;
  font-weight: $font-weight-medium;
  min-width: 0;
  word-break: break-word;

  > * {
    min-width: 0;
  }

  & .fallback {
    display: none;
  }

  span:empty + .fallback {
    display: inline-block;
  }
}

.monospace {
  letter-spacing: 0.5px;
  span {
    font-variant-numeric: tabular-nums;
  }
}
