@use '~@gr4vy/poutine-tokens/scss/index.scss' as *;

// increase specificity
.link.link {
  text-decoration: none;
  font-weight: $font-weight-medium;

  &:hover {
    color: $color-blue-70;
    text-decoration: underline;
  }

  &:focus {
    box-shadow: 0 0 0px $border-width-md $color-blue-20;
  }
}
