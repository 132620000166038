@use '~@gr4vy/poutine-tokens/scss/index.scss' as *;

input.input {
  padding: 0;
  font-size: $font-size-xxl;
  font-weight: 700;

  &::placeholder {
    color: $color-gray-70;
  }
}
