@use '~@gr4vy/poutine-tokens/scss/index.scss' as *;

.anchor {
  :global(.ant-anchor) {
    &::before {
      display: none;
    }

    :global(.ant-anchor-ink) {
      display: none !important;
    }

    :global(.ant-anchor-link) {
      padding-top: 0 !important;
      padding-bottom: 40px !important;
      margin-bottom: 12px;
      border-left: 2px solid $color-gray-30;
      position: relative;

      &::before {
        content: '';
        display: block;
        position: absolute;
        width: 8px;
        height: 8px;
        border-radius: 4px;
        background: $color-gray-70;
        left: -5px;
        top: 0;
      }

      &:last-child {
        padding-bottom: 0 !important;
        border-color: transparent;
      }

      :global(.ant-anchor-link-title) {
        color: $color-gray-70;
        font-size: 1rem;
        top: -6px;
      }

      &:global(.ant-anchor-link-active) {
        font-weight: bold;

        &::before {
          background: $color-black;
        }

        &:global(.highlighted) {
          &::before {
            background: $color-blue-60;
          }
        }

        > :global(.ant-anchor-link-title) {
          color: $color-black;
        }
      }
    }
  }
}
