@use '~@gr4vy/poutine-tokens/scss/index.scss' as *;

.footer {
  border-top: 1px solid $color-gray-30;
}

.wrapper {
  position: fixed;
  bottom: 2rem;
}
