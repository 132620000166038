@use '~@gr4vy/poutine-tokens/scss/index.scss' as *;

.wrapper {
  white-space: pre;
}

.mask {
  font-family: $font-mono;
  letter-spacing: -1px;
}

.number {
  font-variant-numeric: tabular-nums;
  letter-spacing: -1.1px;
}
