@use '~@gr4vy/poutine-tokens/scss/index.scss' as *;

.description {
  display: none;

  @media only screen and (min-width: $breakpoint-md) {
    display: block;
  }
}

.sandbox {
  position: relative;
  height: 6px;

  .notch-corners {
    position: absolute;
    content: '';
    width: $size-8;
    height: $size-8;
    margin: 0 auto;
  }

  .notch {
    position: absolute;
    font-size: $font-size-xs;
    top: 6px;
    left: 0;
    right: 0;
    margin: 0 auto;
    line-height: $size-16;
    font-weight: $font-weight-bold;
    text-align: center;
    border-radius: 0px 0px 4px 4px;
    letter-spacing: 0.5px;

    &::before {
      @extend .notch-corners;
      border-radius: 0px 4px 0px 0px;
      box-shadow: 2px -2px 0px 2px $color-amber-50;
      left: -128px;
      right: 0;
    }

    &::after {
      @extend .notch-corners;
      border-radius: 4px 0px 0px 0px;
      box-shadow: -2px -2px 0px 2px $color-amber-50;
      left: 0;
      right: -128px;
    }
  }
}
