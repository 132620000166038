@use '~@gr4vy/poutine-tokens/scss/index.scss' as *;

.summary {
  display: flex;
  margin: 0;
  gap: $space-24;
  flex-direction: column;
}

.inline {
  display: grid;
  grid-template-columns: 172px auto;
  gap: $space-24 $space-32;
}
