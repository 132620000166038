@use '~@gr4vy/poutine-tokens/scss/index.scss' as *;

.titleBarLink {
  position: relative;
  transition: all 0.1s;
  text-align: center;
  color: $color-black;
  font-family: $font-body;
  font-weight: $font-weight-medium;
  letter-spacing: 0.5px;
  padding: $space-8 $space-16;
  border-radius: $radius-rounded;

  &:focus-visible {
    outline: none;
    box-shadow: $shadow-ring-4;
  }

  &:hover {
    color: $color-black;
    background-color: $color-gray-20;
  }

  &.active {
    color: #ffffff;
  }
}
