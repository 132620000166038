@use '~@gr4vy/poutine-tokens/scss/index.scss' as *;

.breadcrumb {
  display: flex;
  font-size: $font-size-sm;
  font-weight: $font-weight-medium;
  height: $size-24;
  align-items: center;
  padding: 0;
  line-height: 1.5;
  list-style: none;
  margin: 0;

  & > * + *:before {
    padding-right: $space-8;
    padding-left: $space-8;
    color: $color-gray-80;
    font-weight: $font-weight-medium;
  }

  @supports (content: 'x' / 'y') {
    & > * + *:before {
      content: '/' / ''; // / '' empty alternative text to avoid screenreader
    }
  }

  @supports not (content: 'x' / 'y') {
    & > * + *:before {
      content: '/';
      alt: '';
    }
  }
}

.breadcrumbItem {
  color: $color-black;

  &:last-child {
    font-weight: $font-weight-bold;
  }

  & > a {
    color: $color-gray-80;
    transition: none;

    &:hover {
      color: $color-blue-60;
      text-decoration: underline;
    }

    &:active {
      color: $color-blue-90;
      text-decoration: underline;
    }

    &:focus {
      padding: 2px 0;
      box-shadow: 0 0 0px $border-width-md $color-blue-60;
    }
  }
}
