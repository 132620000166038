@use '~@gr4vy/poutine-tokens/scss/index.scss' as *;

.button {
  all: unset;

  display: flex;
  width: $size-32;
  height: $size-32;
  border-radius: $radius-circle;
  align-items: center;
  justify-content: center;
  background: transparent;
  transition: all 0.2s linear;
  cursor: pointer;

  &.user {
    background: var(--white);

    & > span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 26px;
      height: 26px;
      border-radius: $radius-circle;
      background-color: $color-white;
    }
  }

  &.active {
    backdrop-filter: brightness(160%);
    background: transparent;
  }

  &:hover {
    backdrop-filter: brightness(140%);
    background: transparent;
  }

  &:focus {
    box-shadow: $shadow-ring-2;
  }

  svg {
    width: $size-24;
    height: $size-24;
  }
}
