@use '~@gr4vy/poutine-tokens/scss/index.scss' as *;

.select {
  &:global(.ant-select) {
    &:global(.ant-select-single) {
      :global(.ant-select-selection-search) {
        line-height: $size-32;
      }
    }

    :global(.ant-select-selector) {
      min-height: $size-32 !important;

      :global(.ant-select-selection-placeholder) {
        line-height: $size-32 !important;
      }

      :global(.ant-select-selection-search-input) {
        height: $size-32;
      }
    }

    :global(.ant-select-selection-item) {
      margin-top: 2px;
    }

    :global(.ant-select-selection-item),
    :global(.ant-select-selection-search) {
      height: $size-20;
      line-height: $size-20;
      align-self: center;

      :global(.ant-select-selection-search-input) {
        height: $size-20 !important;
        line-height: $size-20;
      }
    }
  }
}
